/* eslint-disable no-console */

import {register} from 'register-service-worker'

const version = 2;


if (process.env.NODE_ENV === 'production') {
  register(`/service-worker.js`, {
    ready() {
      console.log(
          '3POS powered by 3pos.de is ready')
    },
    registered(registration) {
      console.log('Service worker has been registered.');

      setInterval(() => {
        registration.update();
      }, 1000 * 60 ); // e.g. hourly checks
    },
    cached() {
      console.log('Content has been cached for offline use.')
    },
    updatefound() {
      console.log('New content is downloading.')
    },
    updated(registration) {
      console.log("Sending update event...");
      console.log("New Version is: "+version);
      document.dispatchEvent(
          new CustomEvent('swUpdated', { detail: registration })
      );
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
