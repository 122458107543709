import ENDPOINTS from "@/plugins/axios/endpoints";

let user = {}

const auth = {
    namespaced: true,
    state: () => ({
        token: localStorage.getItem('token') || null,
        user: user,
        error: false,
        message: ""
    }),
    getters: {
        is_logged: (state) => state.token !== null,
        getUser(state) {
            return state.user
        },
        getToken(state) {
            return state.token
        },
        getMessage(state) {
            return state.message;
        },
        getError(state) {
            return state.error
        }
    },
    mutations: {
        setToken(state, token) {
            state.token = token
        },
        setUser(state, user) {
            state.user = user
        },
        setMessage(state, message) {
            state.message = message
        },
        setError(state, val) {
            state.error = val
        }
    },
    actions: {
        clear({commit}) {
            localStorage.removeItem('token');
            commit('setToken', '')
            commit('setUser', {})
            delete window.axios.defaults.headers.common['Authorization'];
        },
        async logout({dispatch}) {
            return await new Promise((resolve, reject) => {
                window.axios({url: ENDPOINTS.CANTEEN.USER.AUTH.LOGOUT, method: 'POST'})
                    .then(() => {
                        dispatch('clear')
                        resolve(true)
                    })
                    .catch(() => {
                        window.store.commit('alert/show', {
                            message: "Error",
                            visible: true,
                            color: 'error'
                        })
                        reject(false)
                    })
            })

        },
        auth_success({commit}, data) {
            commit('setToken', data.token)
            commit('setUser', data.user)
        }
        ,
        auth_error({dispatch, commit}, message) {
            dispatch('clear');
            commit('setMessage', message)
            commit('setError', true)
        }
        ,
        async checkAuth({dispatch, commit, getters}) {
            commit('setError', false)
            return await new Promise((resolve, reject) => {
                window.axios({url: ENDPOINTS.CANTEEN.USER.AUTH.REFRESH_TOKEN, method: 'POST'})
                    .then(resp => {
                        const token = resp.data.access_token
                        const user = resp.data.user

                        localStorage.setItem('token', token)
                        window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token


                        commit('setError', false)
                        dispatch('auth_success', {
                            token: token,
                            user: user
                        })


                        resolve(true)
                    })
                    .catch((err) => {
                        //show error message if already logged in
                        if (err.response.status == 401) {
                            if (getters.is_logged)
                                dispatch('auth_error', err.response.data.error.message)
                            else
                                dispatch('auth_error', "unauthorized") //todo: need translation
                        }

                        reject(false)
                    })
            })
        },
        login({dispatch}, user) {
            return new Promise((resolve, reject) => {
                window.axios({url: ENDPOINTS.CANTEEN.USER.AUTH.LOGIN, data: user, method: 'POST'})
                    .then(resp => {
                        const token = resp.data.access_token
                        const user = resp.data.user
                        localStorage.setItem('token', token)
                        window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
                        dispatch('auth_success', {
                            token: token,
                            user: user
                        })
                        resolve(resp)
                    })
                    .catch(err => {
                        dispatch('auth_error', err.response.data.error.message)
                        reject(err)
                    })
            })
        },
        userDetails({commit}) {
            return new Promise((resolve, reject) => {
                window.axios({url: ENDPOINTS.CANTEEN.USER.GET, method: 'GET'})
                    .then(resp => {
                        const user = resp.data.data;
                        commit('setUser', user)
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        }
    }

}

export default auth;
