<template>
  <v-menu max-width="300px" offset-y>
    <template v-slot:activator="{on}">
      <v-avatar v-on="on" size="40">
        <v-img src="@/assets/avatars/1.png"/>
      </v-avatar>
    </template>
    <v-list class="pt-0 pb-0 pl-0" dense width="">
      <v-list-item to="/profile" class="pl-1" link>
        <v-list-item-icon class="pr-0 mr-2">
          <v-icon class="pr-0">
            mdi-account
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content class="pl-0">
          <v-list-item-title class="pl-0">
            {{ $t('generic.lang_profile') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="pa-0 ma-0"></v-divider>
      <v-list-item class="pl-1" @click="logout">
        <v-list-item-icon class="pr-0 mr-2">
          <v-icon class="pr-0">
            mdi-logout-variant
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content class="pl-0">
          <v-list-item-title class="pl-0">
            {{ $t('generic.lang_logout') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'User',
  methods: {
    async logout() {
      await this.$store.dispatch('auth/logout').then(() => this.$router.push('/'))
    }
  }
}
</script>

<style scoped>

</style>
