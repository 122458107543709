"use strict";

import Vue from 'vue';
import axios from "axios";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
    // baseURL: process.env.baseURL || process.env.apiUrl || ""
    // timeout: 60 * 1000, // Timeout
    // withCredentials: true, // Check cross-site Access-Control

    baseURL: process.env.VUE_APP_API_HOST,
    responseType: 'json',
//    proxy: process.env.VUE_APP_PROXY_HOST,
    headers: {
        common: {
            /*      'Content-Type': 'application/x-www-form-urlencoded',
                  'system-id': process.env.VUE_APP_SYSTEM_ID,
                  'auth-token': process.env.VUE_APP_AUTH_TOKEN,
                  'api-key': process.env.VUE_APP_API_KEY,*/
        }
    },
};

if (localStorage.getItem('token'))
    config.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');

const _axios = axios.create(config);


_axios.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        window.store.commit('loader/setUrl', config.baseURL + config.url.split('/?')[0])
        if (config.method === 'get' || config.method === 'head')
            window.store.commit('loader/show', true);
        return config;
    },
    function (error) {
        // Do something with request error
        window.store.commit('loader/show', false);
        return Promise.reject(error);
    }
);


// Add a response interceptor
_axios.interceptors.response.use(
    function (response) {
        // Do something with response data
        window.store.commit('loader/show', false);
        return response;
    },
    function (error) {
        // Do something with response error
        window.store.commit('loader/show', false);
        return Promise.reject(error);
    }
);


Plugin.install = function (Vue/*, options*/) {
    Vue.axios = _axios;
    window.axios = _axios;
    Object.defineProperties(Vue.prototype, {
        axios: {
            get() {
                return _axios;
            }
        },
        $axios: {
            get() {
                return _axios;
            }
        },
    });
};

Vue.use(Plugin)

export default Plugin;
