<template>
  <div class="pa-0 ma-0 px-0 mx-0">
    <v-app-bar
        app
        class="pt-2"
        clipped-right
        color="#fafafa"
        elevate-on-scroll
        fixed
        min-height="80px"
    >
      <v-app-bar-nav-icon v-if="!this.drawer" class="primary--text" x-large @click="drawer=!drawer"/>
      <v-btn v-else icon @click="drawer=!drawer">
        <v-icon class="" color="primary" x-large>
          mdi-keyboard-backspace
        </v-icon>
      </v-btn>
      <h4 class="text-muted ml-2" style="padding-top: 7px !important;">
        {{ this.$route.name.split('-')[this.$route.name.split('-').length - 1].toUpperCase() }}
      </h4>
      <v-spacer/>
      <!--      <v-btn class="mr-0" icon>
              <v-icon color="primary">
                mdi-email-outline
              </v-icon>
            </v-btn>
            <v-btn class="mr-2" icon>
              <v-icon color="primary">
                mdi-bell
              </v-icon>
            </v-btn>-->
      <user/>
    </v-app-bar>
    <v-navigation-drawer
        v-model="drawer"
        :mini-variant="!this.drawer"
        :permanent="this.$vuetify.breakpoint.mdAndUp"
        app
    >
      <v-list id="user_left_bar" class="text-center mt-2 text-left" nav>
        <v-list-item class="text-left">
          <v-toolbar-title v-if="this.drawer" class="mx-auto ma-auto">
            <v-img src="@/assets/logos/3pos.svg" width="50%"/>
          </v-toolbar-title>
          <v-toolbar-title v-else class="mx-auto ma-auto">
            <v-img src="@/assets/logos/favicon.svg" width="100%"/>
          </v-toolbar-title>
        </v-list-item>
      </v-list>
      <v-list
          class="mt-12"
          nav
      >
        <template v-for="(route , i) in routes">
          <v-list-item color="primary" v-if="!route.isGroup && !route.meta.hide" :key="i" :to="route.path.split(':')[0]"
                       link>
            <v-list-item-icon>
              <v-icon>{{ route.meta.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-left">{{ route.name }}</v-list-item-title>
          </v-list-item>
          <v-list-group
              :key="i"
              v-else-if="route.isGroup && !route.meta.hide"
              :group="route.path"
              color="primary"
          >
            <!-- this template is for the title of top-level items with children -->
            <template v-slot:activator>
              <v-list-item class="pl-0">
                <v-list-item-icon>
                  <v-icon>{{ route.meta.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="text-left">{{ route.name }}</v-list-item-title>
              </v-list-item>
            </template>
            <!-- this template is for the children/sub-items (2nd level) -->
            <template v-for="(subItem) in route.children">
              <!-- another v-if to determine if there's a 3rd level -->
              <!-- if there is NOT a 3rd level -->
              <v-list-item
                  :key="subItem.name"
                  :class="drawer? 'ml-5' : ''"
                  :to="(route.path + subItem.path).split(':')[0]"
              >
                <v-list-item-icon class="">
                  <v-icon>{{ subItem.meta.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="ml-0">
                  {{ subItem.name }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-list-group>
        </template>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import routes from '@/router/routes'
import User from '@/components/dashboard/Navigation/User'

export default {
  name: 'TopBar',
  components: {User},
  data: () => {
    return {
      routes,
      drawer: null
    }
  },
  mounted() {
    this.drawer = false
  },
  created() {
    this.drawer = false
  },
}
</script>

<style scoped>

.v-list .v-list-item--active {
  color: none;
}


#user_left_bar {
  /*background-image: linear-gradient(rgb(25, 118, 210, 0.5), rgb(25, 118, 210, 0.5)), url("/images/user-info-bg.jpg");*/
  height: auto !important;
  background-size: 100%;
  background-position: center;
}

#prepend_nav {
  height: 64px !important;
}

#app-bar-ext {
  border-radius: 100px !important;
}
</style>
