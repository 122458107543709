import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import '@/plugins/bootstrap-vue'
import '@/plugins/axios/axios'
import App from '@/App.vue'
import vuetify from '@/plugins/vuetify';
import router from '@/router'
import Store from "@/store/index";
import './registerServiceWorker'
import "@/plugins/currency";
import VueI18n from 'vue-i18n';
import messages from './i18n';
import * as Sentry from "@sentry/vue";
import {Integrations} from "@sentry/tracing";
import VueSwal from 'vue-swal'
/**
 * moment js
 */
import moment from 'moment-timezone'


Sentry.init({
    Vue,
    dsn: "https://dc0f8caf63164a2b826f0d1d8e69392c@sentry.3pos.de/7",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

/**
 * vue swal
 */

Vue.use(VueSwal);

window.i18n = i18n;
/**
 * load layouts
 * */
Vue.component('default-layout', require('@/layouts/default').default);
Vue.component('dashboard-layout', require('@/layouts/dashboard').default);

moment.updateLocale('de', {
    week: {
        dow: 1, // Monday is the first day of the week.
    }
});
moment.tz.setDefault("Europe/Berlin");
Vue.prototype.$moment = moment;

Vue.config.productionTip = false

Vue.use(VueI18n);

export const i18n = new VueI18n({
    locale: 'de',
    fallbackLocale: 'de',
    silentTranslationWarn: true, //DISABLE WARNINGS
    messages
});


Vue.mixin({
    methods: {
        replaceMonth(date) {
            if (date && Array.isArray(date.split(' ')) && date.split(' ').length >= 3) {
                let month = date.split(' ')[1];
                let de_months = moment.localeData("de").months();
                let en_months = moment.localeData("en").months();
                let index = de_months.indexOf(month);
                return index > 0 ? date.replace(month, en_months[index]) : date
            }
        }
    }
})

new Vue({
    i18n,
    vuetify,
    router,
    store: Store,
    render: h => h(App)
}).$mount('#app')
