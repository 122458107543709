import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

/**
 * internationalization config
 */

import de from 'vuetify/es5/locale/de';
import en from 'vuetify/es5/locale/en';
import fr from 'vuetify/es5/locale/fr';


export default new Vuetify({
    lang: {
        locales: {de, en, fr},
        current: process.env.VUE_APP_I18N_LOCALE || 'de',
        fallback: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'de'
    },
    icons: {
        iconfont: 'mdi'// default - only for display purposes
    },
    theme: {
        dark: false, // From 2.0 You have to select the theme dark or light here,
        themes: {
            light: {
                primary: '#F84611', // #E53935
                secondary: '#FFCDD2',
                accent: '#3F51B5'
            }
        }
    }
});


//todo:OI add change language (create new state)
