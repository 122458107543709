const loader = {
    namespaced: true,
    state: () => ({
        visible: false,
        url: ""
    }),
    getters: {
        loading(state) {
            return state.visible
        }
    },
    mutations: {
        show(state, val) {
            state.visible = val
        },
        setUrl(state, val) {
            state.url = val
        }
    }

}

export default loader;
