<template>
  <div id="app">
    <component :is="layout">
      <!--      <message/>-->
      <loader/>
      <transition name="bounce" mode="out-in">
        <router-view/>
      </transition>
      <!--      <v-snackbar
                v-model="visible"
                top
                :color="color"
                :timeout="10000"
            >
              {{ message }}

              <template v-slot:action="{ attrs }">
                <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="visible = false"
                >
                  Close
                </v-btn>
              </template>
            </v-snackbar>-->
    </component>

    <PWACheck></PWACheck>

  </div>
</template>


<script>
import Message from "@/components/widgets/Message";
import Loader from "@/components/widgets/Loader";
import {mapState} from 'vuex'
import PWACheck from "./components/PWACheck";

const default_layout = "default";
export default {

  watch: {
    visible(val) {
      if (val)
        this.$swal({
          title: this.color.toUpperCase(),
          text: this.message || (this.color === 'error' ? "an error occurred please try again later" : "operation has been successfully completed"),
          icon: this.color,
        })
    }
  },
  components: {Loader, Message,PWACheck},
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + '-layout'
    },
    ...mapState([
      'alert'
    ]),
    message() {
      return this.alert.message
    },
    color() {
      return this.alert.color
    },
    visible: {
      get() {
        return this.alert.visible
      },
      set(val) {
        this.$store.commit('alert/show', {visible: val})
      }
    }
  }
}
</script>
<style lang="css">

a:active, a:hover {
  text-decoration: none;
}

#app {
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

* {
  font-family: Roboto, sans-serif !important;
}

#nav {
  padding: 30px;

a {
  font-weight: bold;
  color: #2c3e50;

&
.router-link-exact-active {
  color: #42b983;
}

}
}
</style>
