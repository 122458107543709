<template>
  <v-snackbar v-model="showSnackbar" :timeout="0" top center>
    <b>Es ist ein Update verfügbar!</b>
    <v-spacer />
    <v-btn dark text color="#FFC203" @click.stop="refreshApp">Aktualisieren</v-btn>
  </v-snackbar>
</template>

<script>
export default {
  name: "PWACheck",

  data() {
    return {
      showSnackbar: false,
      refreshing: false,
      registration: null,
    }
  },

  created() {
    // Listen for swUpdated event and display refresh snackbar as required.
    document.addEventListener('swUpdated', this.showRefreshUI, { once: true });

    // Refresh all open app tabs when a new service worker is installed.
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return;
      this.refreshing = true;
      window.location.reload();
    });
  },

  methods: {
    showRefreshUI(e) {
      this.registration = e.detail;
      this.showSnackbar = true;
    },
    refreshApp() {
      this.showSnackbar = false;

      // Protect against missing registration.waiting.
      if (!this.registration || !this.registration.waiting) { return; }
      this.registration.waiting.postMessage('skipWaiting');
    },
  },
}
</script>
