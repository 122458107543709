<template>
  <transition class="v-enter v-enter-active" :duration="{ enter: 500, leave: 800 }">
    <v-app id="inspire">

      <top-bar/>
      <v-main v-bind:class="this.$vuetify.breakpoint.smAndDown? '' : 'main_container'" class="fill-height">
        <slot></slot>
      </v-main>

    </v-app>
  </transition>
</template>

<script>
export default {
  name: "dashboard",
  components: {
    'top-bar': require('@/components/dashboard/Navigation/TopBar').default
  },

  methods: {}
}
</script>

<style>
.main_container {
  padding-top: 130px !important;
  background-color: #fafafa;
}

html {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}

.button--green {
  display: inline-block;
  border-radius: 4px;
  border: 1px solid #3b8070;
  color: #3b8070;
  text-decoration: none;
  padding: 10px 30px;
}

.button--green:hover {
  color: #fff;
  background-color: #3b8070;
}

.button--grey {
  display: inline-block;
  border-radius: 4px;
  border: 1px solid #35495e;
  color: #35495e;
  text-decoration: none;
  padding: 10px 30px;
  margin-left: 15px;
}

.button--grey:hover {
  color: #fff;
  background-color: #35495e;
}
</style>
