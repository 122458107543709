import Vue from 'vue';

import VueCurrencyFilter from 'vue-currency-filter'

Vue.use(VueCurrencyFilter,
    { // default name 'currency'
        symbol: '€',
        thousandsSeparator: '.',
        fractionCount: 2,
        fractionSeparator: ',',
        symbolPosition: '',
        symbolSpacing: true
    });
