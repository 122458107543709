const ENDPOINTS = {
    CANTEEN: {
        USER: {
            AUTH: {
                LOGIN: "/canteen/login",
                REFRESH_TOKEN: "/canteen/refresh_token",
                LOGOUT: "/canteen/logout",
                PASSWORD: {
                    RESET: "/canteen/password"
                }
            },
            UPDATE: "/canteen/users",
            GET: "/canteen/user-details"
        },
        MENU: {
            GET: "/canteen/menu"
        },
        ORDER: {
            GET: "/canteen/orders",
            CREATE: "/canteen/orders",
            PDF: "/canteen/OrderPdf"
        },
        ALLERGEN: {
            USER: {
                GET: '/canteen/users-allergens'
            },
            GET: '/canteen/allergens'
        },
        SETTINGS: {
            NOTE: "/canteen/notes",
            CONFIG: ""
        },
        PAYMENT: {
            CHARGE: "/canteen/payment/charge",
            TRANSACTION: {
                GET_ALL: "/canteen/payment/transactions",
                DOWNLOAD: "/canteen/transactionPdf"
            }
        }
    }
}

export default ENDPOINTS;
