/**
 * @author Omar IZEM
 * this module allow us to manage selected items by logged user
 * @type {{mutations: {}, state: (function(): {Items: []}), getters: {}, namespaced: boolean}}
 */
const order = {
    namespaced: true,
    state: () => ({
        emptyDays: {},
        Items: [
            /*
            {
                timestamp: value (unix timestamp),
                day: value (string: monday ....),
                quantity: value (integer)
                item: value (object)
            }
             */
        ]
    }),
    getters: {
        total: (state) => {
            if (Array.isArray(state.Items) && state.Items.length > 0) {
                return state.Items.reduce((total, item) => total + (item.quantity * parseFloat(item.item.price)), 0);
            } else return 0;
        },
        items: (state) => state.Items, // return all selected items
        /**
         * @param state
         * @returns {function(*): (number|*|number)}
         */
        quantity: (state) => (payload) => {
            //search for requested item if selected
            const search = state.Items.find((item) => item.item.uuid === payload.item.uuid && payload.day.toLowerCase() === item.day.toLowerCase());
            //item founded
            if (search) return search.quantity;

            //item not found
            return 0;
        },
        /**
         * @param payload {item: {} , day: ""}
         * @param state
         * @returns {function(*): boolean}
         */
        is_selected: (state) => (payload) => {

            if (!(typeof payload.item === 'object')) return false;
            //search for requested item if selected in specified day
            const search = state.Items.find((elt) => elt.item.uuid === payload.item.uuid && payload.day.toLowerCase() === elt.day.toLowerCase());

            //get item index
            const index = state.Items.indexOf(search);
            return index > -1;
        },
        /**
         * @param payload {item: {} , day: ""}
         * @param state
         * @returns {function(*): ({}|null)}
         */
        item: (state) => (payload) => {
            //search for requested item if selected
            const search = state.Items.find((item) => item.item.uuid === payload.uuid && payload.day.toLowerCase() === item.day.toLowerCase());

            //item founded
            if (search) return Object.assign({}, search);

            return null;
        },

        /**
         * return total price for an item in specific day
         * @param state
         * @returns {function(*): (number)}
         */
        price: (state) => (payload) => {
            //search for requested item if selected
            const search = state.Items.find((item) => item.item.uuid === payload.uuid && payload.day.toLowerCase() === item.day.toLowerCase());

            //item founded
            if (search) return (search.item.price * search.quantity)

            //item not found
            return 0;
        },
        order_request: (state) => {
            let days = {...state.emptyDays};

            //let browse select items
            state.Items.forEach((item) => {

                //we reserve an array for item day if not reserved yet
                if (!Array.isArray(days[item.timestamp])) days[item.timestamp] = [];

                //push item
                days[item.timestamp].push({
                    day: Math.round(item.timestamp),
                    item: {
                        uuid: item.item.uuid
                    },
                    quantity: item.quantity
                })
            })

            return days;
        }
    },
    mutations: {
        /**
         * push items into @Item
         * @param state
         * @param item ({})
         */
        push: (state, item) => {
            if (!(typeof item === 'object')) return false;

            state.Items.push(item);
        },
        /**
         * @param state
         * @param payload {item: {} , day: ""}
         */
        plus: (state, payload) => {
            if (!(typeof payload.item === 'object')) return false;

            if (!Array.isArray(state.Items)) state.Items = []

            /*search item position*/
            const search = state.Items.find((item) => item.item.uuid === payload.item.uuid && payload.day.toLowerCase() === item.day.toLowerCase());
            const index = state.Items.indexOf(search);

            if (index === -1) return; // break if no item found

            //update quantity
            ++state.Items[index].quantity;
            state.Items[index].item = Object.assign({}, payload.item)
        },
        /**
         * @param state
         * @param payload {item: {} , day: ""}
         */
        minus: (state, payload) => {
            if (!(typeof payload.item === 'object')) return false;

            /*search item position*/
            const search = state.Items.find((item) => item.item.uuid === payload.item.uuid && payload.day.toLowerCase() === item.day.toLowerCase());
            const index = state.Items.indexOf(search);

            if (index === -1) return; // break if no item found

            //update quantity
            --state.Items[index].quantity;
            state.Items[index].item = Object.assign({}, payload.item)
            //delete item if quantity is 0
            if (state.Items[index].quantity === 0) {

                const timestamp = state.Items[index].timestamp;
                state.Items.splice(index, 1);
                state.emptyDays[timestamp] = [];
            }

        },
        /**
         * @param state
         * @param payload
         * @returns {boolean}
         */
        remove: (state, payload) => {
            if (!(typeof payload.item === 'object')) return false;

            /*search item position*/
            const search = state.Items.find((item) => item.item.uuid === payload.item.uuid && payload.day.toLowerCase() === item.day.toLowerCase());

            const index = state.Items.indexOf(search);

            if (index === -1) return; // break if no item found
            const timestamp = state.Items[index].timestamp;
            state.Items.splice(index, 1);


            /*    if (!(typeof window.store.getters['order/order_request'] === 'object') || (typeof window.store.getters['order/order_request'] === 'object' && !Array.isArray(window.store.getters['order/order_request'][timestamp])))
                {

                }*/
            state.emptyDays[timestamp] = [];

            //if (Object.prototype.hasOwnProperty(state.Items , ))
        },
        clear: (state) => {
            state.Items = []
        }
    },
    actions: {
        /**
         * select new item
         * @param state
         * @param getters
         * @param payload
         */
        addToCard: ({getters, commit}, payload = {}) => {
            const item = payload.item;
            const day = payload.day;
            const timestamp = payload.timestamp; // should be in unix time
            //check if selected item is an object
            if (!(typeof payload.item === 'object')) return;
            /**
             * check if this item is already selected
             */
            if (getters.is_selected({day: day, item: item}))
                commit('plus', {
                    item: item,
                    day: day
                })
            else
                //add new item
                commit('push', {
                    timestamp: timestamp,
                    day: day,
                    quantity: 1,
                    item: Object.assign({}, item)
                })


        }
    }
}

export default order;
