const alert = {
    namespaced: true,
    state: () => ({
        message: '',
        color: 'error',
        visible: false
    }),
    mutations: {
        show(state, data) {
            state.message = data.message
            state.color = data.color
            state.visible = data.visible
        }
    }

}

export default alert;
