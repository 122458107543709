<template>
  <v-snackbar
      v-model="visible"
      top
      :color="color"
      :timeout="10000"
  >
    {{ message }}

    <template v-slot:action="{ attrs }">
      <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="visible = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: "Message",
  computed: {
    ...mapState([
      'alert'
    ]),
    message() {
      return this.alert.message
    },
    color() {
      return this.alert.color
    },
    visible: {
      get() {
        return this.alert.visible
      },
      set(val) {
        this.$store.commit('alert/show', {visible: val})
      }
    }
  }
}
</script>

<style scoped>

</style>
