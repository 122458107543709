import Vue from 'vue'
import Vuex from 'vuex'

import config from '@/store/config/index';

import alert from "@/store/alerts";

import loader from "@/store/loader";

import auth from "@/store/auth";

import order from "@/store/order";

Vue.use(Vuex)

const Store = new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        config,
        alert,
        loader,
        auth,
        order
    }
})

window.store = Store;

export default Store;
