/*const conf = window.axios.get('/settings').then((res) => res.data.times)*/
/*


async function loadConfig() {
    // fetch data from a url endpoint
    await window.axios.get('/canteen/settings')
        .then((result) => {
            localStorage.setItem('times', JSON.stringify(result.data.times))
            localStorage.setItem('images', JSON.stringify(result.data.images))
        });
}

loadConfig().then(resolve => setTimeout(resolve, 1000))

*/

const config = {
    namespaced: true,
    state: () => ({
        times: JSON.parse(localStorage.getItem('times')) || {},
        images: JSON.parse(localStorage.getItem('images')) || {},
    }),
    mutations: {
        setTimes(state, data) {
            state.times = data
        },
        setImages(state, data) {
            state.images = data
        }
    }
    , getters: {
        getTimes: (state) => {
            return state.times
        },
        getImages: (state) => {
            return state.images
        },
        /**
         * check if settings are set from POS interface
         * @param state
         */
        notConfigured: (state) => {
            try {
                if (!state.times.deadlines.to_order) return true
                //if (!state.times.deadlines.to_cancel) return true
                return !state.times.deadlines.to_cancel;
                // if (!state.images.login_background) return true
                //return !state.images.note_image;
            } catch (e) {
                if (e) return true
            }
        }
    },
    actions: {
        load({
                 commit
             }) {
            return new Promise(((resolve, reject) => {
                window.axios({
                    url: '/canteen/settings',
                    method: "GET"
                }).then((res) => {
                    commit('setTimes', res.data.times)
                    commit('setImages', res.data.images)

                    resolve(res)
                }).catch((err) => {
                    reject(err)
                })
            }))
        }
    }
}

export default config;
