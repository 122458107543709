<template>
  <v-dialog content-class="elevation-0" class="elevation-0" :overlay-opacity="0.5" overlay-color="primary" persistent
            v-model="visible">
    <v-card elevation="0" class="transparent">
      <v-card-text class="transparent">
        <v-progress-circular size="100" indeterminate color="white"/>
      </v-card-text>

      <v-card-actions class="text-center">
        <span class="text-center mx-auto text-white">
          {{ this.url }}
        </span>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "Loader",
  computed: {
    ...mapState([
      'loader'
    ]),
    url() {
      return this.loader.url
    },
    visible: {
      get() {
        return this.loader.visible
      },
      set(val) {
        this.$store.commit('loader/show', val)
      }
    }
  }
}
</script>

<style scoped>


.v-dialog {
  box-shadow: none !important;
}

</style>
