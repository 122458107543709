import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = require('@/router/routes.js').default;

const router = new VueRouter({
    routes,
    mode: 'history'
})

/*

router.afterEach((to, from, next) => {
    window.store.commit('loader/show', false);
    next()
})*/
router.beforeEach((to, from, next) => {
    /**
     * check if the admin is configured required settings from POS interface
     */
    /*if (window.store.getters['config/notConfigured']) window.store.dispatch('config/load');
    if (window.store.getters['config/notConfigured'] && (from.name !== 'missingConfig' && to.name !== 'missingConfig')) next('/missing-config');*/
    /*auth middleware*/
    if (to.meta.requiresAuth === true) {
        /*auth middleware*/
        window.store.dispatch('auth/checkAuth')
            .then(() => next()).catch(() => {
            next('/')
        })
    } else {
        next()
    }
})

window.router = router;
export default router
