import config from "@/middlewares/config/index";
import multiguard from 'vue-router-multiguard';

const routes = [
    {
        name: 'Anmelden',
        path: '/',
        isGroup: false,
        meta: {
            hide: true,
            icon: '',
            layout: "default"
        },
        component: require("@/views/Login").default,
        beforeEnter: multiguard([config])
    },
    {
        name: 'Menü',
        path: '/menu',
        isGroup: false,
        meta: {
            icon: 'mdi-clipboard-list-outline',
            requiresAuth: true,
            layout: "dashboard"
        },
        component: () => import('@/views/menu/index'),
        beforeEnter: multiguard([config])
    },
    {
        name: 'Archiv',
        path: '/orders',
        isGroup: false,
        meta: {
            icon: 'mdi-archive',
            requiresAuth: true,
            layout: "dashboard"
        },
        component: () => import('@/views/orders/index'),
        beforeEnter: multiguard([config])
    },
    {
        name: 'Zahlungen',
        path: '/payout/:status?',
        isGroup: false,
        meta: {
            icon: 'mdi-currency-eur',
            requiresAuth: true,
            layout: "dashboard"
        },
        component: () => import('@/views/payout/index'),
        beforeEnter: multiguard([config])
    },
    {
        name: 'Profil',
        path: '/profile',
        isGroup: false,
        meta: {
            hide: true,
            requiresAuth: true,
            layout: "dashboard"
        },
        component: () => import('@/views/profile/index'),
        beforeEnter: multiguard([config])
    },
    {
        name: 'forgotPassword',
        path: '/forgot-password',
        isGroup: false,
        meta: {
            hide: true,
        },
        component: () => import('@/views/forgotPassword/index')
    },

    /**DISPLAY CONFIGURATION REQUIREMENT IF EXISTS*/
    {
        name: 'missingConfig',
        path: '/missing-config',
        isGroup: false,
        meta: {
            hide: true,
        },
        component: () => import('@/views/missingConfig/index')
    }
]

export default routes
