<template>
  <div v-bind:style="{ 'background-image': 'url(' + this.bg + ') !important' }" class="container">

    <v-img style="position: absolute; z-index: 0; top: 0; bottom: 0; right: 0; left: 0; opacity: 0.8" :src="this.bg"/>
    <!--Absolute background-->
    <div
        class="canteen-Login-background">
      <div class="canteen-login-gray-background"></div>
      <div class="canteen-Login-Left-line"></div>
      <div class="canteen-Login-Right-line"></div>
    </div>
    <!--login form-->
    <v-card id="login_card" class="border-none Card--shadow--large" max-width="600" tile>
      <v-card-title class="p-text-color font-weight-bold">{{ $t('generic.lang_signIn') }}</v-card-title>
      <v-card-text class="pt-4">
        <v-form lazy-validation ref="loginForm">
          <v-row no-gutters>

            <v-col class="text-left" cols="12">
              <v-alert style="background-color: red" color="red" dense dismissible v-model="error" type="error">
                {{ this.message }}
              </v-alert>
            </v-col>
            <v-col class="text-left" cols="12">
              <v-text-field @keypress.enter="login" color=" primary
              " :rules="[rules.required, rules.min]" :loading="this.loading"
                            :disabled="this.loading"
                            prepend-inner-icon="mdi-card-account-details" v-model="form.username" outlined dense
                            :label="this.$t('generic.lang_cardId')"
              >

              </v-text-field>
            </v-col>

            <v-col class="text-left" cols="12">

              <v-text-field @keypress.enter="login" :rules="[rules.required, rules.min]" :loading="this.loading"
                            :disabled="this.loading"
                            :append-icon="visible ? 'mdi-eye' : 'mdi-eye-off'" :type="visible ? 'text' : 'password'"
                            @click:append="visible = !visible"
                            prepend-inner-icon="mdi-lock" v-model="form.password" outlined dense
                            :label="this.$t('generic.lang_password')"
              >

              </v-text-field>
            </v-col>
            <v-col class="text-right pt-0 mt-0" cols="12">
              <a id="forgot_password" class="p-text-color" href="/forgot-password">
                {{ $t('generic.lang_forgotPassword') }}
              </a>
            </v-col>
            <v-col class="pt-4" cols="12">
              <v-btn
                  :loading="this.loading" :disabled="this.loading"
                  id="login_btn"
                  large
                  @click="login"
                  elevation="0"
                  block
                  class="mx-auto ma-auto"
                  v-bind:style="{
              'background-color': 'rgb(248, 70, 17, 0.9)',
              color: 'white'
            }"
              >
                {{ $t('generic.lang_continue') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'

const auth = createNamespacedHelpers('auth');
export default {
  name: "Login",
  computed: {
    loaded() {
      return this.$store.getters['loader/loading']
    },
    ...auth.mapGetters({
      getError: 'getError',
      message: 'getMessage'
    }),
    error:
        {
          get() {
            return this.getError
          },
          set(val) {
            this.$store.commit('auth/setError', val)
          }
        }
  },
  data() {
    return {
      bg: "",
      rules: {
        required: value => !!value || this.$t('generic.lang_requiredField'),
        min: v => v.length >= 4 || this.$t('generic.lang_min4Characters')
      },
      loading: false,
      visible: false,
      form: {
        username: '',
        password: ''
      },
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.bg = '' + window.store.getters['config/getImages'].login_background + '')
  },
  methods: {
    login() {
      if (!this.$refs.loginForm.validate()) {
        return
      }
      this.loading = true
      this.$store.dispatch('auth/login', this.form).then(() => {
        this.$router.push('/menu')
      })
          .finally(() => this.loading = false)
    }
  }
}
</script>

<style scoped>

.container {
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /*background-image: url("/bg/Home.jpg");*/
  background-size: cover;
  background-position: center;
}

* {
  font-family: 'Muli', sans-serif !important;
}

/*start backgound css*/
.canteen-Login-background {
  position: fixed;
  left: 0;
  right: 0;
  top: -300px;
  bottom: 0;
  z-index: 0;
  transform: skewY(-12deg);
}

.canteen-login-gray-background {
  position: absolute;
  top: -1000px;
  left: 0;
  right: 0;
  transform-origin: 0 50%;
  height: 1698px;
  background-color: rgba(248, 70, 17, 0.1);
}

.canteen-Login-Left-line {
  top: 698px;
  left: -10px;
  right: calc(50% + 405px);
  height: 40px;
  background-color: #7a73ff;
}

.canteen-Login-Left-line {
  top: 698px;
  left: -10px;
  right: calc(50% + 405px);
  height: 40px;
  background-color: rgb(248, 70, 17);
  display: none;
}

.canteen-Login-Right-line, .canteen-Login-Left-line {
  position: absolute;
}

.canteen-Login-Right-line {
  top: 658px;
  right: -10px;
  left: calc(50% + 430px);
  height: 40px;
  background-color: rgba(248, 70, 17, 0.9);
  display: none;
}

/*end backgound css*/

/*card css*/
.Card--shadow--large {
  box-shadow: 0 15px 35px 0 rgba(60, 66, 87, .08), 0 5px 15px 0 rgba(0, 0, 0, .12);
  border-radius: 4px;
}

#login_card {
  padding: 30px 42px;
}

/*login btn*/
#login_btn {
  outline: 0;
  border: 0;
}

/*forgot password*/
#forgot_password {
  margin-bottom: 10px !important;
  line-height: 20px;
  white-space: nowrap;
  font-weight: 500;
  color: rgba(248, 70, 17, 0.6) !important;
}

#forgot_password:active, #forgot_password:hover {
  text-decoration: none;
}

.p-text-color {
  color: #3c4257 !important;
}

input:active, input:focus {
  outline-color: rgba(248, 70, 17, 0.4) !important;
}

@media screen and (max-width: 600px) {

  #login_card {
    padding: 12px 10px;
  }

  .canteen-Login-Right-line {
    top: 58px !important;
    right: 0px !important;
    left: calc(50% + 30px);
    height: 40px;
    background-color: rgba(248, 70, 17, 0.9);
  }

}
</style>
